import { Container } from 'unstated'

class FiltersContainer extends Container {
  state = {
    filters: [],
    sidebarOpen: false,
  }

  toggleSidebar = () => {
    const prevState = this.state.sidebarOpen
    this.setState({ sidebarOpen: !prevState })
  }

  updateFilters = f => {
    const prevState = this.state.filters
    const idx = prevState.indexOf(f)
    if (idx === -1) {
      prevState.push(f)
    } else {
      prevState.splice(idx, 1)
    }
    this.setState({ filters: prevState })
  }

  resetFilters = () => {
    this.setState({ filters: [] })
  }
}

export default FiltersContainer
