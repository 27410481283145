import React from 'react'
import PropTypes from 'prop-types'
import { FaFilter } from 'react-icons/fa'
import { Subscribe } from 'unstated'

import FiltersContainer from '../state/FiltersContainer'

import '../css/pageTitle.scss'

class PageTitle extends React.Component {
  render() {
    const { count, totalCount, pageTitle } = this.props
    return (
      <Subscribe to={[FiltersContainer]}>
        {filters => (
          <h3 className="page-title" key={`0`}>
            <div className="icon flex">
              <FaFilter
                title="Filters"
                alt="Filters"
                className="sidebar-toggle mh3"
                onClick={() => filters.toggleSidebar()}
              />
              <div className="filter-count">{filters.state.filters.length}</div>
            </div>
            {pageTitle}
            <span className="count">
              showing <b>{count}</b> of <b>{totalCount}</b>
            </span>
          </h3>
        )}
      </Subscribe>
    )
  }
}

PageTitle.propTypes = {
  pageTitle: PropTypes.string,
  count: PropTypes.number,
  totalCount: PropTypes.number,
}

export default PageTitle
