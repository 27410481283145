import React from 'react'
import PropTypes from 'prop-types'
import {StaticQuery, graphql} from 'gatsby'

import '../css/layout.scss'

class Layout extends React.Component {
  render() {
    return (<StaticQuery query={graphql `
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
            markdownRemark(frontmatter: { title: { eq: "cc" } }) {
              id
              html
            }
          }
        `} render={data => <div className="header">{this.props.children}</div>}/>)
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
