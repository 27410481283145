import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Filters from '../components/filters'
import Directory from '../components/directory'
import SEO from '../components/seo'

import '../css/directory.scss'

class DirectoryPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      entries: [],
      filters: {},
    }
  }

  componentDidMount() {
    const { edges } = this.props.data.allMarkdownRemark
    let filters = {}
    const f = [
      'type',
      'platform',
      'access',
      'domain',
      'status',
      'host',
      'server',
    ]
    _.forEach(f, e => (filters[e] = []))
    const entries = edges.filter(
      edge =>
        edge.node.frontmatter.collections &&
        edge.node.frontmatter.collections.indexOf('directory') !== -1
    )
    _.forEach(entries, entry => {
      let entryFilters = []
      const { frontmatter } = entry.node
      _.forEach(f, e => {
        filters[e].push(frontmatter[e])
        if (_.isArray(frontmatter[e])) {
          _.forEach(frontmatter[e], g => {
            entryFilters.push(e + '-' + _.camelCase(g))
          })
        } else {
          entryFilters.push(e + '-' + _.camelCase(frontmatter[e]))
        }
        _.merge(entry.node, { entryFilters })
      })
    })

    this.setState(state => ({
      entries,
      filters,
    }))
  }

  render() {
    const { filters, entries } = this.state
    return (
      <Layout>
        <div className="directory main flex">
          <SEO
            title="Home"
            keywords={['DIF', 'directory', 'Digital Integrity Fellowship']}
          />
          <Filters filters={filters} />
          <Directory entries={entries} />
        </div>
      </Layout>
    )
  }
}

export default DirectoryPage

export const pageQuery = graphql`
  query directoryQuery {
    allMarkdownRemark {
      edges {
        node {
          headings {
            value
            depth
          }
          id
          html
          frontmatter {
            title
            collections
            description
            status
            type
            icon
            server
            domain
            host
            access
            comments
            platform
            url
          }
        }
      }
    }
  }
`
