import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
// import { FaCheck, FaTimes } from 'react-icons/fa'
import Filter from './filter'

class FilterSet extends React.Component {
  render() {
    const { filterSet, filterTitle } = this.props
    return (
      <div className="flex flex-column">
        <h4 className="pa0 ma0 pr2 mb1 b">{filterTitle}</h4>
        <div className="flex flex-wrap">
          {_.map(filterSet, (filter, idx) => (
            <Filter key={idx} filter={filter} filterTitle={filterTitle} />
          ))}
        </div>
      </div>
    )
  }
}

FilterSet.propTypes = {
  filterSet: PropTypes.array,
  filterTitle: PropTypes.string,
}

export default FilterSet
