import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Subscribe } from 'unstated'
import { FaCheck } from 'react-icons/fa'

import FiltersContainer from '../state/FiltersContainer'

class Filter extends Component {
  render() {
    const { filter, filterTitle } = this.props
    const id = _.camelCase(filterTitle) + '-' + _.camelCase(filter)
    return (
      <Subscribe to={[FiltersContainer]}>
        {filters => (
          <div
            onClick={() => filters.updateFilters(id)}
            className={`filter flex items-center ${
              filters.state.filters.indexOf(id) > -1 ? 'active' : ''
            }`}
            id={id}
          >
            <FaCheck className="filter-icon mr2" />
            {filter}
          </div>
        )}
      </Subscribe>
    )
  }
}

Filter.propTypes = {
  filter: PropTypes.string,
  filterTitle: PropTypes.string,
}

export default Filter
