import React from 'react'
import PropTypes from 'prop-types'
import { FaLink } from 'react-icons/fa'
import _ from 'lodash'

class DirectoryEntry extends React.Component {
  render() {
    const { frontmatter } = this.props.entry
    const { title, description, icon, url } = frontmatter
    return (
      <div id={`entry-${_.camelCase(title)}`} className={`entry `}>
        <div className="title">{title}</div>
        <div
          className="entry-image flex flex-column"
          style={{ backgroundImage: `url(${icon}#color=#fff)` }}
        >
          <div className="meta">
            {_.map(['type', 'platform', 'access', 'host'], (e, i) => (
              <div key={i} className={`meta-item meta-${e}`}>
                <h4>
                  {e}: {` `}
                </h4>
                <div>
                  {(() => {
                    if (_.isArray(frontmatter[e])) {
                      return (
                        <div>
                          {_.map(frontmatter[e], (el, idx) => (
                            <span key={idx}>
                              {el}
                              {`, `}
                            </span>
                          ))}
                        </div>
                      )
                    } else {
                      return <span>{frontmatter[e]}</span>
                    }
                  })()}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="summary">{description}</div>
        <a className="url" href={url} target="_blank" rel="noopener noreferrer">
          <FaLink className="mr2" />
          {url}
        </a>
      </div>
    )
  }
}

DirectoryEntry.propTypes = {
  entry: PropTypes.object,
}

export default DirectoryEntry
