import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CSSTransitionGroup } from 'react-transition-group'
import { Subscribe } from 'unstated'

import FiltersContainer from '../state/FiltersContainer'

import PageTitle from './pageTitle'
import DirectoryEntry from '../components/directoryEntry'

class Directory extends React.Component {
  constructor(props) {
    super(props)
    this.state = { entries: [] }
  }

  render() {
    const { entries } = this.props
    return (
      <Subscribe to={[FiltersContainer]}>
        {filters => {
          const filteredEntries =
            _.size(filters.state.filters) > 0
              ? _.filter(entries, entry => {
                  return (
                    _.size(
                      _.intersection(
                        entry.node.entryFilters,
                        filters.state.filters
                      )
                    ) > 0
                  )
                })
              : entries

          return (
            <div className="entries flex w-100 flex-column ">
              <PageTitle
                pageTitle={`DIF Services Directory`}
                count={_.size(filteredEntries)}
                totalCount={_.size(entries)}
              />
              <div className="flex flex-wrap contents-between">
                {_.map(filteredEntries, (entry, i) => (
                  <CSSTransitionGroup
                    transitionName="guide"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={400}
                    key={i}
                  >
                    <DirectoryEntry key={i} entry={entry.node} />
                  </CSSTransitionGroup>
                ))}
              </div>
            </div>
          )
        }}
      </Subscribe>
    )
  }
}

Directory.propTypes = {
  entries: PropTypes.array,
}

export default Directory
