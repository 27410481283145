import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Subscribe } from 'unstated'
import { FaTimes, FaUndo } from 'react-icons/fa'

import FiltersContainer from '../state/FiltersContainer'

import FilterSet from './filterSet'
import '../css/filters.scss'

class Filters extends React.Component {
  render() {
    const { filters } = this.props
    return (
      <Subscribe to={[FiltersContainer]}>
        {toggler => (
          <div
            className="filters"
            style={{ marginLeft: toggler.state.sidebarOpen ? '0' : '-30%' }}
          >
            <FaTimes
              className="toggle-close"
              onClick={() => toggler.toggleSidebar()}
            />
            <div className="flex items-end mv2">
              <h3>Filters</h3>
              {toggler.state.filters.length > 0 && (
                <div
                  className="reset-filters mh3"
                  onClick={() => toggler.resetFilters()}
                >
                  reset all
                  <FaUndo className="ml2" />
                </div>
              )}
            </div>

            {_.map(filters, (filterSet, idx) => (
              <div key={idx} className={`filter-set ${idx}`}>
                <FilterSet
                  filterTitle={_.upperCase(idx)}
                  filterSet={_.chain(filterSet)
                    .flattenDeep()
                    .sortBy()
                    .uniq()
                    .value()}
                />
              </div>
            ))}
          </div>
        )}
      </Subscribe>
    )
  }
}

Filters.propTypes = {
  filters: PropTypes.object,
  tags: PropTypes.array,
  topics: PropTypes.array,
  packages: PropTypes.array,
}

export default Filters
